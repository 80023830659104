import React from "react";
import Helmet from "react-helmet";
import Layout from "../layout/MainLayout";
import BookTimeline from "../components/BookTimeline/BookTimeline";
import "./bookTimelinePage.css";

export default class BookTimelinePage extends React.Component {
  render() {
    return (
      <Layout>
        <div className="BookTimelinePage-container">
          <Helmet title="Book timenlines" />
          <h3>When did I read what books?</h3>
          <div className="timelinePage-container">
            <BookTimeline />
          </div>
        </div>
      </Layout>
    );
  }
}
